import * as React from 'react'
import { PageReportSortKey, PageReportSortState } from '../../components/grid/PageGridItem'
import { INIT_SORT_STATE } from '../../util/hooks/api/PageReport/usePageReport'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

export interface State {
  readonly initialized: boolean // ゴールAPIなど数個のAPI呼び出しが完了し、ページレポートAPIを呼び出す準備ができたか
  readonly loading: boolean // 画面がローディング中か
  readonly sortState: PageReportSortState
  readonly page: number // コンテンツページ数
  readonly currentPage: number // コンテンツの選択中のページ
  readonly itemCount: number // 総アイテム数
  readonly dispCount: number // 表示アイテム数
  readonly projectId: string
}

export class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  initialize = async (
    projectId: string,
    disp: number,
    sortState: PageReportSortState,
    isSettingsCompleted: boolean,
  ) => {
    this.setState({ ...this.state, loading: true })

    // レポート表示の準備ができてない場合はここまで
    if (!isSettingsCompleted) {
      this.setState({
        ...this.state,
        loading: false,
      })
      return
    }

    this.setState({
      ...this.state,
      initialized: true,
      currentPage: 0,
      dispCount: disp,
      projectId,
      sortState,
    })
  }

  onSuccessFetchPageReports = (pageReportsDataCount: number) => {
    this.setState({
      ...this.state,
      loading: false,
      page: Math.ceil(pageReportsDataCount / this.state.dispCount),
      itemCount: pageReportsDataCount,
    })
  }

  onErrorFetchPageReports = () => {
    this.setState({
      ...this.state,
      loading: false,
    })
  }

  // ソートを実行
  onSort = (key: PageReportSortKey) => {
    // 一度全ての項目のソート状態をリセットする
    for (const e in this.state.sortState) {
      const elem = e as PageReportSortKey
      if (key !== elem) {
        this.state.sortState[elem] = 'none'
      }
    }

    if (this.state.sortState[key] === 'down') {
      this.state.sortState[key] = 'up'
    } else {
      this.state.sortState[key] = 'down'
    }
    this.setState({
      ...this.state,
      currentPage: 0,
    })
    return this.state.sortState
  }

  // グリッドの表示ページを変更
  onPaginateChange = async (selected: number) => {
    // ページが切り替わっていない場合は処理しない
    if (this.state.currentPage === selected) return
    this.setState({ ...this.state, currentPage: selected })
  }

  // 表示件数変更
  onDispCountChange = (dispCount: number) => {
    this.setState({
      ...this.state,
      dispCount: dispCount,
      currentPage: 0,
    })
  }

  resetPaginate = () => {
    this.setState({
      ...this.state,
      currentPage: 0,
    })
  }
}

const initialState: State = {
  initialized: false,
  loading: true,
  sortState: INIT_SORT_STATE,
  page: 1,
  currentPage: 0,
  itemCount: 0,
  dispCount: 20,
  projectId: '',
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
