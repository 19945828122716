import * as React from 'react'
import { useQuery } from '@tanstack/react-query'

import { Percent } from '../../../Parse'
import { CalenderState } from '../../../../components/common/DayPickerRange'
import { SearchOption } from './types'
import { SCOPE_TYPE, ScopeType, useScopeType } from '../../useScopeType'
import { CustomFilterState } from '../Filter/types'
import { getDateStringYMD } from '../../../Date'
import { getScopeTypeApiValue } from '../../../getScopeTypeApiValue'
import { makeReportFilters } from '../../../makeReportFilters'
import { ErrorType, request } from '../../../request'
import { TIMELINE_REPORT_QUERY_KEY } from '../constants'
import { DeviceType, getDeviceLayoutNumber, useDeviceType } from '../../useDeviceType'
import { useFilterContentEventsExists } from '../../cookie/useFilterContentEventsExists'
import { useGoalId } from '../../useGoalId'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../../contexts/CustomFilterContext'
import { PageReportError, getSearchOptions, setPageReportError } from './usePageReport'
import { CHOICE_DATASET_KEYS } from './TimelineReport/constants'
import { AggregationDateType, RequestBody, TimeLineReportData, TimelineReportResponse } from './TimelineReport/types'
import { useTimelineAggregationDateType } from '../../cookie/useTimelineAggregationDateType'
import { PageReportContext } from '../../../../contexts/PageReportContext'

interface Props {
  projectId: number
  enabled?: boolean
}

/**
 * 時系列グラフデータ取得APIのhook
 *
 * @param {Object} props - The props object containing the necessary parameters.
 * @param {number} props.projectId - The ID of the project.
 * @param {boolean} [props.enabled=true] - Boolean value indicating whether the query should be enabled.
 */
export const useTimelineReport = ({ projectId, enabled = true }: Props) => {
  const { searchText, searchOptions } = React.useContext(PageReportContext)
  const {
    state: { calenderState, uuid },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)

  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId })
  const { filterContentEventsExists } = useFilterContentEventsExists()
  const { timelineAggregationDateType: aggregationDateType } = useTimelineAggregationDateType()

  const [error, setError] = React.useState<PageReportError | null>(null)

  const queryKey = [
    TIMELINE_REPORT_QUERY_KEY,
    {
      uuid,
      projectId,
      aggregationDateType,
      scopeType,
      deviceType,
      calenderState,
      goalId,
      searchText,
      searchOptions,
      customFilterState,
      filterContentEventsExists,
    },
  ]

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const requestBody = makeTimelineRequestBody(
        aggregationDateType,
        scopeType,
        deviceType,
        goalId,
        calenderState,
        searchText,
        searchOptions,
        customFilterState,
        filterContentEventsExists,
      )
      return await request<TimelineReportResponse>(
        'POST',
        `/api/projects/${projectId}/timeline_report/`,
        true,
        JSON.stringify(requestBody),
        true,
      )
    },
    select: (response) => {
      if (error) setError(null)
      return transform(response, scopeType)
    },
    onError: (error: ErrorType) =>
      setPageReportError({
        apiErrorResponse: error,
        setError,
        generalErrorMessage: '時系列データの取得に失敗しました。',
      }),
    enabled: enabled,
  })

  return {
    ...queryResult,
    error,
  }
}

/**
 * APIのレスポンス結果を、扱いやすい形に変換する
 *
 * @param {TimelineReportResponse} response - The response to transform.
 * @param {ScopeType} scopeType - The type of scope.
 * @returns {TimeLineReportData} The transformed data.
 */
const transform = (response: TimelineReportResponse, scopeType: ScopeType): TimeLineReportData => {
  return {
    labels: response.results.map((item) => item.aggregation_date.replace(/-/g, '/')),
    datasets: [
      scopeType === SCOPE_TYPE.SESSION
        ? {
            label: 'セッション数',
            key: CHOICE_DATASET_KEYS.VIEW_COUNT,
            data: response.results.map((item) => item.session_count),
            formattedData: response.results.map((item) => item.session_count.toLocaleString()),
            formattedSummary: response.all_session_count.toLocaleString(),
          }
        : {
            label: 'ユーザー数',
            key: CHOICE_DATASET_KEYS.VIEW_COUNT,
            data: response.results.map((item) => item.user_count),
            formattedData: response.results.map((item) => item.user_count.toLocaleString()),
            formattedSummary: response.all_user_count.toLocaleString(),
          },
      {
        label: 'ランディング数',
        key: CHOICE_DATASET_KEYS.LANDING_COUNT,
        data: response.results.map((item) => item.landing_count),
        formattedData: response.results.map((item) => item.landing_count.toLocaleString()),
        formattedSummary: response.all_landing_count.toLocaleString(),
      },
      {
        label: '直帰率',
        key: CHOICE_DATASET_KEYS.BOUNCE_RATE,
        data: response.results.map((item) => Percent.parse(item.bounce_rate)),
        formattedData: response.results.map(
          (item) => (item.bounce_rate === 0 ? 0 : Percent.parse(item.bounce_rate).toFixed(1)) + '%',
        ),
        formattedSummary:
          (response.all_bounce_rate === 0 ? 0 : Percent.parse(response.all_bounce_rate).toFixed(1)) + '%',
        isPercentage: true,
      },
      {
        label: 'ゴール数',
        key: CHOICE_DATASET_KEYS.GOAL_COUNT,
        data: response.results.map((item) => item.goal_count),
        formattedData: response.results.map((item) => item.goal_count.toLocaleString()),
        formattedSummary: response.all_goal_count.toLocaleString(),
      },
      {
        label: 'ゴール率',
        key: CHOICE_DATASET_KEYS.GOAL_RATE,
        data: response.results.map((item) => Percent.parse(item.goal_rate)),
        formattedData: response.results.map(
          (item) => (item.goal_rate === 0 ? 0 : Percent.parse(item.goal_rate).toFixed(2)) + '%',
        ),
        formattedSummary: (response.all_goal_rate === 0 ? 0 : Percent.parse(response.all_goal_rate).toFixed(2)) + '%',
        isPercentage: true,
      },
    ],
  }
}

/**
 * APIのリクエストパラメータ用のオブジェクトを生成する
 *
 * @param {AggregationDateType} aggregationDateType - The type of aggregation date.
 * @param {ScopeType} scopeType - The type of scope.
 * @param {DeviceType} deviceType - The type of device.
 * @param {number} goalId - The ID of the goal.
 * @param {CalenderState} calenderState - The state of the calendar.
 * @param {string} searchText - The search text.
 * @param {SearchOption[]} searchOptions - List of search options for page reports
 * @param {CustomFilterState[]} customFilterState - An array of custom filter states.
 * @param {boolean} filterContentEventsExists - Indicates if the filter for content events exists.
 * @returns {RequestBody} The request body object.
 */
export const makeTimelineRequestBody = (
  aggregationDateType: AggregationDateType,
  scopeType: ScopeType,
  deviceType: DeviceType,
  goalId: number,
  calenderState: CalenderState,
  searchText: string,
  searchOptions: SearchOption[],
  customFilterState: CustomFilterState[],
  filterContentEventsExists: boolean,
): RequestBody => {
  const body = {
    aggregation_date_type: aggregationDateType,
    goal_id: goalId,
    page_layout: getDeviceLayoutNumber(deviceType),
    search_from: getDateStringYMD(calenderState.startDate, '-'),
    search_to: getDateStringYMD(calenderState.endDate, '-'),
    scope_type: getScopeTypeApiValue(scopeType),
    filters: makeReportFilters(customFilterState),
    filter_content_events_exists: filterContentEventsExists,
    ...getSearchOptions(searchOptions),
  }
  if (searchText) {
    Object.assign(body, { page: searchText })
  }
  return body
}
