import { getDateStringYMD } from './Date'

export namespace UrlParams {
  // パラメータ削除
  export function deleteParams(url: URL, name: string) {
    if (url.searchParams.get(name)) {
      url.searchParams.delete(name)
    }
    return url
  }

  // レポートページ用パラメータの解析
  export function getReportPageUrlParams(params: URLSearchParams) {
    const start_date = params.get('start_date')
    const end_date = params.get('end_date')
    const device = params.get('device')
    const goal = params.get('goal')
    const disp = params.get('disp')
    return { start_date, end_date, device, goal, disp }
  }

  // カレンダー情報からURLパラメータを作成
  export function createDateParam(url: URL, startDate: Date, endDate: Date) {
    const start = getDateStringYMD(startDate, '')
    const end = getDateStringYMD(endDate, '')
    if (url.searchParams.get('start_date')) {
      url.searchParams.set('start_date', start)
    } else {
      url.searchParams.append('start_date', start)
    }

    if (url.searchParams.get('end_date')) {
      url.searchParams.set('end_date', end)
    } else {
      url.searchParams.append('end_date', end)
    }

    return url
  }

  // デバイス情報からURLパラメータを作成
  export function createDeviceParam(url: URL, device: string) {
    if (url.searchParams.get('device')) {
      url.searchParams.set('device', device)
    } else {
      url.searchParams.append('device', device)
    }

    return url
  }

  // ゴール情報からURLパラメータを作成
  export function createGoalParam(url: URL, goal: number) {
    if (url.searchParams.get('goal')) {
      url.searchParams.set('goal', goal.toString())
    } else {
      url.searchParams.append('goal', goal.toString())
    }

    return url
  }

  // 表示数からURLパラメータを作成
  export function createDispParam(url: URL, disp: number) {
    if (url.searchParams.get('disp')) {
      url.searchParams.set('disp', disp.toString())
    } else {
      url.searchParams.append('disp', disp.toString())
    }

    return url
  }

  /**
   * ハッシュパラメータから指定されたパラメータを削除する
   * @param url - 対象のURL
   * @param paramName - 削除するパラメータ名
   * @returns パラメータが削除されたURL
   */
  export function deleteHashParams(url: URL, paramName: string): string {
    const [basePath, hashParams] = url.hash.split('?')
    if (!hashParams) return url.href

    const params = new URLSearchParams(hashParams)
    params.delete(paramName)

    const newHashParams = params.toString()
    // ハッシュパラメータが全て無くなった場合はハッシュ自体を削除
    const newHash = newHashParams ? `${basePath}?${newHashParams}` : ''

    const newUrl = new URL(url.href)
    newUrl.hash = newHash
    return newUrl.href
  }
}
