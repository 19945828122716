import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { ContextType, useContextState } from './state'

export const PageReportContext = React.createContext<ContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

/**
 * ページレポートのstate情報を他のレポート系ページで使用できるようProviderで定義する
 *
 * @param {Object} props - The props object containing the children component.
 * @param {React.ReactNode} props.children - The child component(s) to be wrapped.
 */
export function PageReportProvider({ children }: Props) {
  const contextValue = useContextState()

  return <PageReportContext.Provider value={contextValue}>{children}</PageReportContext.Provider>
}
