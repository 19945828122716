import React from 'react'
import styled from 'styled-components'

import { MultipleChart, MULTIPLE_CHART_HEIGHT } from '../../../components/charts/MultipleChart'
import { Checkbox } from '../../../components/common/Checkbox'
import { colors } from '../../../styleConstants'
import { useTimelineReport } from '../../../util/hooks/api/PageReport/useTimelineReport'
import {
  TimeLineReportData,
  ChoiceDatasetKey,
  AggregationDateType,
} from '../../../util/hooks/api/PageReport/TimelineReport/types'
import { AGGREGATION_DATE_TYPE_OPTIONS } from '../../../util/hooks/api/PageReport/TimelineReport/constants'
import { EmbeddedLoading } from '../../../components/common/EmbeddedLoading'
import { Select } from '../../../components/common/Select'
import { InteractiveTooltip } from '../../../components/Tooltip'
import { useTimelineAggregationDateType } from '../../../util/hooks/cookie/useTimelineAggregationDateType'
import { useTimelineFirstChoice } from '../../../util/hooks/cookie/useTimelineFirstChoice'
import { useTimelineSecondChoice } from '../../../util/hooks/cookie/useTimelineSecondChoice'

const CHOICE_ORDER_TYPE = {
  FIRST: 'first',
  SECOND: 'second',
}
type ChoiceOrderType = typeof CHOICE_ORDER_TYPE[keyof typeof CHOICE_ORDER_TYPE] | null

interface Props {
  projectId: number
}

const CONTAINER_MARGIN_BOTTOM = 14
const BUTTON_SECTION_HEIGHT = 56
const BUTTON_SECTION_MARGIN_BOTTOM = 16
const LEGENDS_HEIGHT = 18
const LOADING_HEIGHT =
  CONTAINER_MARGIN_BOTTOM +
  BUTTON_SECTION_HEIGHT +
  BUTTON_SECTION_MARGIN_BOTTOM +
  LEGENDS_HEIGHT +
  MULTIPLE_CHART_HEIGHT

export function TimelineChart({ projectId }: Props) {
  const { timelineAggregationDateType: aggregationDateType, setTimelineAggregationDateType: setAggregationDateType } =
    useTimelineAggregationDateType()

  const { data, isError, isLoading } = useTimelineReport({
    projectId: Number(projectId),
  })

  const { firstChoice, setFirstChoice } = useTimelineFirstChoice()
  const { secondChoice, setSecondChoice } = useTimelineSecondChoice()

  if (isLoading)
    return (
      <div style={{ height: `${LOADING_HEIGHT}px` }}>
        <EmbeddedLoading />
      </div>
    )
  if (isError) return <></>

  const firstDataset = data.datasets.find((dataset) => dataset?.key === firstChoice)
  const secondDataset = data.datasets.find((dataset) => dataset?.key === secondChoice)
  const choiceData: TimeLineReportData = {
    ...data,
    datasets: [firstDataset, secondDataset],
  }

  const isMaxChoiceReached = !!firstChoice && !!secondChoice

  const handleClickChoices = (choice: ChoiceDatasetKey) => {
    // 選択済みであれば解除
    if (firstChoice === choice) {
      setFirstChoice(null)
      return
    }
    if (secondChoice === choice) {
      setSecondChoice(null)
      return
    }

    // 1軸目未選択であれば1軸目を選択状態にする
    if (!firstChoice) {
      setFirstChoice(choice)
      return
    }

    // 1軸目選択中は、2軸目を常に上書きで変更する
    setSecondChoice(choice)
  }

  const handleChangeAggregationDateType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAggregationDateType(Number(e.target.value) as AggregationDateType)
  }

  const getChoiceOrderType = (key: ChoiceDatasetKey): ChoiceOrderType => {
    if (firstChoice === key) return CHOICE_ORDER_TYPE.FIRST
    if (secondChoice === key) return CHOICE_ORDER_TYPE.SECOND
    return null
  }

  return (
    <Container>
      <FormSection>
        <CheckboxSection>
          {data.datasets.map((dataset) => {
            if (!dataset) return null
            const { label, key } = dataset
            const choiceOrderType = getChoiceOrderType(key)

            return (
              <InteractiveTooltip
                key={key}
                content={'同時に選択できるのは2つまでです'}
                disabled={choiceOrderType === null && !isMaxChoiceReached}
                offset={[15, 5]}
              >
                <CheckboxLabel choiceOrderType={choiceOrderType} onClick={() => handleClickChoices(key)}>
                  <div>
                    <StyledCheckbox checked={firstChoice === key || secondChoice === key} />
                    <span>{label}</span>
                  </div>
                  <CheckboxSummary>
                    <CheckboxSummaryTitle>{dataset.isPercentage ? '平均:' : '合計:'}</CheckboxSummaryTitle>
                    <CheckboxSummaryValue>{dataset.formattedSummary}</CheckboxSummaryValue>
                  </CheckboxSummary>
                </CheckboxLabel>
              </InteractiveTooltip>
            )
          })}
        </CheckboxSection>

        <StyledSelect
          options={AGGREGATION_DATE_TYPE_OPTIONS}
          value={aggregationDateType}
          onChange={handleChangeAggregationDateType}
        />
      </FormSection>

      {(firstChoice || secondChoice) && (
        <>
          <Legends>
            <FirstLegend>{firstDataset?.label}</FirstLegend>
            {secondDataset && <SecondLegend>{secondDataset?.label}</SecondLegend>}
          </Legends>

          <MultipleChart
            key={`${firstChoice}-${secondChoice}`} // 再描画しないと棒グラフの描画から背景色が消えるバグが出るので、keyを指定して強制再描画するようにした
            data={choiceData}
          />
        </>
      )}
    </Container>
  )
}
const Container = styled.div`
  margin-bottom: ${CONTAINER_MARGIN_BOTTOM}px;
  background: ${colors.white};
  line-height: 1.5;
`

const FormSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${BUTTON_SECTION_MARGIN_BOTTOM}px;
`

const CheckboxSection = styled.div`
  display: flex;
  flex-direction: rows;
  gap: 10px;
  font-size: 14px;
`

const CheckboxLabel = styled.div<{ choiceOrderType: ChoiceOrderType }>`
  display: block;
  min-width: 140px;
  padding: 5px 16px;
  border: 1px solid ${colors.gray250};
  border-radius: 5px;
  background: ${colors.white};
  font-size: 12px;
  font-weight: bold;
  color: ${colors.gray750};
  cursor: pointer;

  ${({ choiceOrderType }) =>
    choiceOrderType === CHOICE_ORDER_TYPE.FIRST &&
    `
      border: 1px solid ${colors.contentBlue.blue5};
      background-color: ${colors.contentBlue.blue1};
      color: ${colors.black2};
    `};

  ${({ choiceOrderType }) =>
    choiceOrderType === CHOICE_ORDER_TYPE.SECOND &&
    `
      border: 1px solid ${colors.contentOrange.orange4};
      background-color: ${colors.contentOrange.orange1};
      font-weight: bold;
      color: ${colors.black2};
    `};
`

const CheckboxSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2px;
`

const CheckboxSummaryTitle = styled.div`
  font-size: 10px;
`

const CheckboxSummaryValue = styled.div`
  font-size: 16px;
`

const StyledSelect = styled(Select)`
  font-size: 12px;
  height: 30px;
  padding: 0 20px 0 10px; // 継承コンポーネントのpaddingを打ち消すため上下は0とする
`

const StyledCheckbox = styled(Checkbox)`
  width: 11px;
  height: 11px;
  margin-right: 0.5rem;
`

const Legends = styled.div`
  display: flex;
  justify-content: space-between;
`

const LegendBase = styled.div`
  font-size: 12px;
  font-weight: bold;
`

const FirstLegend = styled(LegendBase)`
  color: ${colors.contentBlue.blue5};
`

const SecondLegend = styled(LegendBase)`
  color: ${colors.contentOrange.orange6};
`
