import * as React from 'react'
import { SearchOption } from '../../util/hooks/api/PageReport/types'

export interface ContextType {
  readonly searchText: string
  readonly setSearchText: (value: string) => void
  readonly searchOptions: SearchOption[]
  readonly setSearchOptions: (value: SearchOption[]) => void
}

/**
 * ページレポートで使用するstate情報を返す
 *
 * @return {ContextType} state and setter
 */
export function useContextState(): ContextType {
  const [searchText, setSearchText] = React.useState('')
  const [searchOptions, setSearchOptions] = React.useState<SearchOption[]>([])

  return {
    searchText,
    setSearchText,
    searchOptions,
    setSearchOptions,
  }
}
