import * as React from 'react'

import { CalenderState } from '../../../../components/common/DayPickerRange'
import { useScopeType } from '../../useScopeType'
import { CustomFilterState } from '../Filter/types'
import { request } from '../../../request'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { useDeviceType } from '../../useDeviceType'
import { useFilterContentEventsExists } from '../../cookie/useFilterContentEventsExists'
import { useGoalId } from '../../useGoalId'
import { downloadBlobFile } from '../../../downloadUtils'
import { PageReportSortState } from '../../../../components/grid/PageGridItem'
import { makePageReportRequestBody } from './usePageReport'
import { PageReportContext } from '../../../../contexts/PageReportContext'

export interface PageReportsDownloadResponse {
  readonly download_url: string
}

interface Props {
  projectId: string
  calenderState: CalenderState
  sortState: PageReportSortState
  customFilterState: CustomFilterState[]
}

export const usePageReportCsvDownload = ({
  projectId,
  calenderState,
  sortState,
  customFilterState,
}: Props): UseMutationResult<void, unknown, void, unknown> => {
  const { searchText, searchOptions } = React.useContext(PageReportContext)
  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { filterContentEventsExists } = useFilterContentEventsExists()
  const { goalId } = useGoalId({ projectId: Number(projectId) })

  return useMutation({
    mutationFn: async () => {
      const requestBody = makePageReportRequestBody(
        scopeType,
        deviceType,
        goalId,
        calenderState,
        undefined,
        undefined,
        sortState,
        searchText,
        searchOptions,
        customFilterState,
        filterContentEventsExists,
      )
      const res = await request<PageReportsDownloadResponse>(
        'POST',
        `/api/projects/${projectId}/page_reports/download/`,
        true,
        requestBody,
      )
      await downloadBlobFile({
        downloadUrl: res.download_url,
        defaultFilename: 'contentanalytics_pagereport.csv',
      })
    },
  })
}
